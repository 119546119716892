import React from 'react'
import {Button, Card} from 'react-daisyui'
import {useGetCMSPropertiesQuery} from '../../services/guestPortalApi'
import {formatEndDate, formatStartDate} from '../../services/utils'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faUser} from '@fortawesome/free-solid-svg-icons'
import {useNavigate} from 'react-router-dom'
import StaysEmptyState from '../../assets/stays-empty-state.png'

const StayCard = ({
  stay,
  viewReservation,
  onManageStay
}: {
  stay: any
  viewReservation?: boolean
  onManageStay?: Function
}) => {
  const propertiesQuery = useGetCMSPropertiesQuery({})

  const navigate = useNavigate()

  return (
    <Card className="md:card-side bg-white shadow-md">
      {propertiesQuery.data && propertiesQuery.data[stay.propertyId] && (
        <Card className="md:card-side bg-white shadow-md">
          <Card.Image
            src={
              (propertiesQuery.data[stay.propertyId].images &&
                propertiesQuery.data[stay.propertyId].images[0].image) ||
              StaysEmptyState
            }
            alt="Shoes"
            className="h-full object-cover"
          />
          <Card.Body className="p-4 pt-6 min-w-[250px]">
            <p
              className="text-xs uppercase font-semibold btn-cabana pb-1"
              color="secondary"
            >
              {propertiesQuery.data[stay.propertyId].location || ''}
            </p>
            <p className="text-xl pb-1">
              {propertiesQuery.data[stay.propertyId].name}
            </p>
            <div className="container mx-auto text-sm font-normal">
              <p className="pb-1">
                {formatStartDate(stay.checkinDate)} -{' '}
                {formatEndDate(stay.checkoutDate)}
              </p>
              <ul>
                <li className="pb-1 whitespace-nowrap text-ellipsis overflow-hidden">
                  <p>
                    <span className="pr-2">
                      <FontAwesomeIcon
                        icon={faUser}
                        className="text-xs text-primary"
                      />
                    </span>
                    {stay.adults} guest{stay.adults > 1 ? 's' : ''}
                  </p>
                </li>
                <li className="pb-1 whitespace-nowrap text-ellipsis overflow-hidden">
                  <span className="pr-2">
                    <FontAwesomeIcon
                      icon={faUser}
                      className="text-xs text-primary"
                    />
                  </span>
                  {stay.unitType}
                </li>
                <li className="pb-1 whitespace-nowrap text-ellipsis overflow-hidden">
                  <span className="pr-2">
                    <FontAwesomeIcon
                      icon={faUser}
                      className="text-xs text-primary"
                    />
                  </span>
                  {stay.reservationId}
                </li>
              </ul>
            </div>

            {stay.status === 'NoShow' && (
              <div className="text-xs text-gray-500 pt-8">
                No Show on{' '}
                {new Date(stay.checkinDate).toLocaleDateString('en-US', {
                  timeZone: 'UTC'
                })}
              </div>
            )}
            {viewReservation ? (
              <Card.Actions className="justify-end pt-5">
                <div className="container flex justify-end pt-25">
                  <Button
                    className="btn-sm text-xs lowercase font-thin"
                    color="secondary"
                    onClick={evt => {
                      navigate(`/member/stays/${stay.reservationId}`)
                      if (onManageStay) {
                        onManageStay(stay)
                      }
                    }}
                  >
                    View Reservation
                  </Button>
                </div>
              </Card.Actions>
            ) : (
              <Card.Actions className="justify-end pt-5">
                <div className="container flex justify-end pt-25">
                  <Button
                    className="btn-sm text-xs lowercase font-thin invisible"
                    color="secondary"
                  >
                    View Reservation
                  </Button>
                </div>
              </Card.Actions>
            )}
            {stay.cancelDate && (
              <div className="text-xs text-gray-500 pb-2">
                Canceled on{' '}
                {new Date(stay.cancelDate).toLocaleDateString('en-US', {
                  timeZone: 'UTC'
                })}
              </div>
            )}
          </Card.Body>
        </Card>
      )}
    </Card>
  )
}

export default StayCard
