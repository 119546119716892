import React from 'react'
import {Routes, Route} from 'react-router-dom'
import AuthenticatedPage from './routes/AuthenticatedPage'
import LoginPage from './routes/LoginPage'
import LogoutPage from './routes/LogoutPage'
import NotFoundPage from './routes/NotFoundPage'
import MemberPage from './routes/MemberPage'
import MemberDashboardPage from './routes/MemberDashboardPage'

const AppRoutes = () => {
  return (
    <Routes>
      {/* Login Routes */}
      <Route path="login" element={<LoginPage />} />
      <Route path="logout" element={<LogoutPage />} />
      <Route path="confirm" element={<LoginPage />} />

      {/* Landing Routes */}
      {/* TODO: not integrating checkin to the portal in this phase */}
      {/* <Route path="checkin" element={<CheckinWelcomePage />} /> */}

      {/* Authenticated Routes */}
      <Route path="/" element={<AuthenticatedPage />}>
        {/* Guest Portal Routes */}
        {/* TODO: not supporting guest login at this phase */}
        {/* <Route path="guest" element={<GuestPage />}>
          <Route index element={<GuestReservationInfoPage />} />
          <Route path="cancellation" element={<GuestCancellationPage />} />
          <Route path="payment" element={<GuestPaymentPage />} />
          <Route path="verification" element={<GuestVerificationPage />} />
        </Route> */}

        {/* Member Portal Routes */}
        <Route path="member" element={<MemberPage />}>
          <Route index element={<MemberDashboardPage defaultTab="stays" />} />
          <Route
            path="stays"
            element={<MemberDashboardPage defaultTab="stays" />}
          ></Route>
          <Route
            path="stays/:reservationId"
            element={<MemberDashboardPage defaultTab="stays" />}
          />
          <Route
            path="profile"
            element={<MemberDashboardPage defaultTab="profile" />}
          />
        </Route>
      </Route>

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  )
}

export default AppRoutes
