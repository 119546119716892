import React, {useState} from 'react'
import {Accordion, Button, Modal} from 'react-daisyui'
import {formatDateWithOptions, formatEndDate} from '../../services/utils'
import {
  useCancelReservationMutation,
  useGetMemberReservationQuery,
  useGetMemberReservationsDetailedQuery
} from '../../services/guestPortalApi'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faChevronRight,
  faCommentDots,
  faEnvelope,
  faPhone
} from '@fortawesome/free-solid-svg-icons'
import MemberUpdatePayment from './MemberUpdatePayment'
import CancellationPolicy from './CancellationPolicy'
import LoadingSpinner, {LoadingSpinnerSize} from '../common/LoadingSpinner'
import {useNavigate} from 'react-router-dom'
import CancelReservationForm from '../account/CancelReservationForm'

interface ManageReservationProps {
  reservationId: string
  onMyStaysClick?: Function
  propertiesData: any
  onStayModification?: Function
}

const ManageReservation = ({
  reservationId,
  onMyStaysClick,
  propertiesData,
  onStayModification
}: ManageReservationProps) => {
  const [
    cancelReservation,
    {data, error, isLoading}
  ] = useCancelReservationMutation()

  const navigate = useNavigate()

  const reservationQuery = useGetMemberReservationQuery({reservationId})

  const [showCancelDialog, setShowCancelDialog] = useState(false)

  const {Dialog} = Modal.useDialog()

  return (
    <div>
      {reservationQuery.isError && (
        <div className="text-red-400">Error loading reservation</div>
      )}

      {reservationQuery.isLoading && (
        <LoadingSpinner size={LoadingSpinnerSize.Large}></LoadingSpinner>
      )}

      {reservationQuery.data ? (
        <div className="">
          <Dialog open={showCancelDialog}>
            <CancelReservationForm
              reservation={reservationQuery.data}
              onCancel={() => {
                setShowCancelDialog(false)
                reservationQuery.refetch()
                if (onStayModification) {
                  onStayModification(reservationQuery.data)
                }
              }}
              onGoBack={() => {
                setShowCancelDialog(false)
              }}
            />
          </Dialog>
          <div className="breadcrumbs text-sm">
            <ul>
              <li>
                <div
                  className="btn btn-xs btn-link no-underline hover:no-underline text-sm font-normal"
                  onClick={evt => {
                    navigate('/member/stays')
                    if (onMyStaysClick) {
                      onMyStaysClick()
                    }
                  }}
                >
                  My Stays
                </div>
              </li>
              <FontAwesomeIcon
                icon={faChevronRight}
                className="faSolid pr-2 text-xs"
              />
              <span className="font-medium">
                {propertiesData[reservationQuery.data.propertyId].location}
                {', '}
                {formatDateWithOptions(reservationQuery.data.checkinDate, {
                  month: 'long',
                  day: 'numeric'
                })}{' '}
                -{' '}
                {formatDateWithOptions(reservationQuery.data.checkoutDate, {
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric'
                })}
              </span>
            </ul>
          </div>
          <div className="collapse collapse-arrow">
            <input type="checkbox" name="res-accordion" />
            <Accordion.Title className="accordion-title collapse-arrow">
              Manage Reservation
            </Accordion.Title>
            <Accordion.Content className="accordion-content">
              <div className="container mx-auto space-y-6">
                <div className=" container space-y-2">
                  <h1 className="font-semibold text-lg">
                    Reservation overview
                  </h1>
                  <div className="bg-gray-100 p-6 pr-16 rounded-lg w-fit">
                    <p className="font-semibold text-lg  max-w-lg">
                      Reservation ID:{' '}
                      <span className="font-light">
                        {reservationQuery.data.reservationId}
                      </span>
                    </p>
                    <div className="grid grid-cols-2 gap-4">
                      <div className="pt-2">
                        <p className="font-semibold uppercase text-sm">
                          Property:
                        </p>
                        <p>{reservationQuery.data.propertyTitle}</p>
                      </div>
                      <div className="pt-2">
                        <p className="font-semibold uppercase text-sm">
                          Unit Type:
                        </p>
                        <p>{reservationQuery.data.unitType}</p>
                      </div>
                    </div>
                    <div className="pt-2 grid grid-cols-2 md:grid-cols-3 gap-4">
                      <div className="">
                        <p className="font-semibold uppercase text-sm">
                          CHECK-IN:
                        </p>
                        <p>
                          {formatEndDate(reservationQuery.data.checkinDate)}
                        </p>
                      </div>
                      <div className="">
                        <p className="font-semibold uppercase text-sm">
                          CHECK-OUT:
                        </p>
                        <p>
                          {formatEndDate(reservationQuery.data.checkoutDate)}
                        </p>
                      </div>

                      <div className="">
                        <p className="font-semibold uppercase text-sm">
                          STATUS:
                        </p>
                        <p>{reservationQuery.data.reservation_status}</p>
                      </div>
                    </div>
                  </div>
                </div>

                {reservationQuery.data.reservation_status === 'Confirmed' &&
                reservationQuery.data.pmStayInfoLink ? (
                  <div className="container space-y-2">
                    <div className="container space-y-2">
                      <h2 className="font-semibold text-lg">
                        Prepare for your stay
                      </h2>
                      {reservationQuery.data.pmStayInfoLink ? (
                        <div className="">
                          <p className="pb-4">
                            Get directions, property information, local
                            recommendations and more with your personalized
                            check-in page.
                          </p>
                          <a
                            href={[
                              `${reservationQuery.data.pmStayInfoLink}`,
                              `?code=${reservationQuery.data.confirmationCode}`
                            ].join('')}
                            target="_blank"
                            className="p-y-4"
                          >
                            <Button color="secondary" className="btn-md">
                              view check-in page
                            </Button>
                          </a>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="container space-y-2">
                      <h2 className="font-semibold text-lg">
                        Cancel your reservation
                      </h2>
                      <p>
                        You can cancel your reservation if you booked with us
                        directly. If you used an online travel agent like
                        Expedia, Booking.com or AirBnb, you need to cancel your
                        reservation with them.
                      </p>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
              {reservationQuery.data.reservation_status === 'Confirmed' &&
              (reservationQuery.data.channel === 'Direct' ||
                reservationQuery.data.channel === 'Ibe') ? (
                <div className="pt-4">
                  <Button
                    color="secondary"
                    className="btn-md btn-outline"
                    onClick={() => setShowCancelDialog(true)}
                  >
                    cancel reservation
                  </Button>
                </div>
              ) : (
                ''
              )}

              <CancellationPolicy reservation={reservationQuery.data} />
              <div className="pt-4">
                Need to do something else? You can email us at
                info@placemakr.com or call {reservationQuery.data.propertyTitle}{' '}
                at {reservationQuery.data.propertyPhone}.
              </div>
            </Accordion.Content>
          </div>

          <div className="collapse collapse-arrow">
            <input type="checkbox" name="res-accordion" />
            <Accordion.Title className="accordion-title">
              Update Payment
            </Accordion.Title>
            <Accordion.Content className="accordion-content">
              <div className="">
                <p className="py-4">
                  Update a payment method to save to your reservation. Your
                  payment method will not be charged at this time. If your
                  reservation wasn’t prepaid, we will run your payment 1 day
                  before check-in.
                </p>
                <MemberUpdatePayment reservation={reservationQuery.data} />
                <p className="py-4">
                  Need to do something else? You can email us at
                  info@placemakr.com or call{' '}
                  {reservationQuery.data.propertyTitle} at {}.
                </p>
              </div>
            </Accordion.Content>
          </div>

          <div className="collapse collapse-arrow">
            <input type="checkbox" name="res-accordion" />
            <Accordion.Title className="accordion-title">
              Get assistance
            </Accordion.Title>
            <Accordion.Content className="accordion-content">
              <p>Need a hand with something else? We're here for you 24/7.</p>
              <ul className="space-y-3 pl-4 pt-3 font-light">
                <li>
                  <p>
                    <span className="pr-2">
                      <FontAwesomeIcon
                        icon={faPhone}
                        className="text-xs text-cabana"
                      />
                    </span>
                    Call at {reservationQuery.data.propertyPhone}
                  </p>
                </li>
                <li>
                  <span className="pr-2">
                    <FontAwesomeIcon
                      icon={faCommentDots}
                      className="text-xs text-cabana"
                    />
                  </span>
                  Text us at {reservationQuery.data.propertyPhone}
                </li>
                <li>
                  <span className="pr-2">
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className="text-xs text-cabana"
                    />
                  </span>
                  Email us at info@placemakr.com
                </li>
              </ul>
            </Accordion.Content>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default ManageReservation
