import React, {useEffect, useRef} from 'react'
import AdyenCheckout from '@adyen/adyen-web'
import {
  CoreOptions,
  PaymentMethodsConfiguration
} from '@adyen/adyen-web/dist/types/core/types'
import {getConfig} from '../../services/config'

const {adyenClientId, adyenEnvironment} = getConfig()

const renderAdyenCheckout = async (
  paymentContainer: any,
  paymentMethods: any,
  paymentSession: any,
  isSavedPayment: boolean,
  onPaymentCompleted: Function,
  onPaymentError: Function
) => {
  const payMessage = isSavedPayment ? 'Save Payment Method' : 'Make Payment'
  const paymentMethodsConfig: PaymentMethodsConfiguration = {
    card: {
      hasHolderName: true,
      holderNameRequired: true,
      billingAddressRequired: true,
      name: 'Credit or Debit Card'
    }
  }

  const configuration: CoreOptions = {
    paymentMethodsResponse: paymentMethods,
    paymentMethodsConfiguration: paymentMethodsConfig,
    clientKey: adyenClientId,
    environment: adyenEnvironment,
    locale: 'en-US',
    session: {
      id: paymentSession?.id,
      sessionData: paymentSession?.sessionData
    },
    translations: {
      'en-US': {
        confirmPurchase: payMessage,
        confirmPreauthorization: payMessage
      }
    },
    onPaymentCompleted: (result: any) => {
      if (onPaymentCompleted) {
        if (result.resultCode === 'Authorised') {
          if (onPaymentCompleted) {
            onPaymentCompleted(result)
          }
        } else {
          // handle immediate payment errors
          if (onPaymentError) {
            onPaymentError()
          }
        }
      }
    },
    onError: (error: any) => {
      console.error('Adyen payment error', error)
      if (onPaymentError) {
        onPaymentError()
      }
    }
  }

  const checkout = await AdyenCheckout(configuration)
  const component = checkout
    .create('dropin', {showPayButton: true})
    .mount(paymentContainer.current)

  // return component cleanup function
  return () => {
    try {
      if (checkout && component) {
        checkout.remove(component)
      }
    } catch (e) {
      console.warn('Failed to cleanup adyen payment component', e)
    }
  }
}

interface PaymentFormProps {
  paymentMethods: any
  paymentSession: any
  isSavedPayment: boolean
  onPaymentCompleted: Function
  onPaymentError: Function
}

const PaymentForm = ({
  paymentMethods,
  paymentSession,
  isSavedPayment,
  onPaymentCompleted,
  onPaymentError
}: PaymentFormProps) => {
  // get reference to container element
  const paymentContainer = useRef(null)

  useEffect(() => {
    if (paymentContainer && paymentSession && typeof window !== 'undefined') {
      const noop = () => {}

      const allowedPaymentMethods = {
        paymentMethods: paymentMethods.paymentMethods.filter(
          (pm: any) => pm.name === 'Credit Card'
        )
      }

      // render the adyen payment component
      const checkoutPromise = renderAdyenCheckout(
        paymentContainer,
        allowedPaymentMethods,
        paymentSession,
        isSavedPayment,
        onPaymentCompleted || noop,
        onPaymentError || noop
      )

      // handle errors
      checkoutPromise.catch(e => {
        console.error('Error rendering Adyen payment form', e)
      })

      // cleanup function - removes payment component
      return () => {
        // invoke the cleanup function
        checkoutPromise.then(cleanup => cleanup())
      }
    } else {
      return
    }
  }, [paymentContainer, paymentSession])

  return <div ref={paymentContainer} className="mb-8" />
}

export default PaymentForm
