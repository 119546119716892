import React, {useState} from 'react'
import {Button, Input} from 'react-daisyui'
import LoadingSpinner, {LoadingSpinnerSize} from '../common/LoadingSpinner'
import clsx from 'clsx'
import {useForm} from 'react-hook-form'
import {signUp} from 'aws-amplify/auth'
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons'

export const CreateMemberForm = ({
  onSignUpSuccess
}: {
  onSignUpSuccess?: Function
}) => {
  const {
    register,
    handleSubmit,
    control,
    formState: {errors},
    watch
  } = useForm()

  const [isSigningUp, setIsSigningUp] = useState(false)

  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const handleSignUp = async (data: any) => {
    event.preventDefault()
    setIsSigningUp(true)
    try {
      const {nextStep} = await signUp({
        username: data.email,
        password: data.password,
        options: {
          userAttributes: {
            given_name: data.given_name,
            phone_number: data.phone_number,
            family_name: data.family_name
          },
          autoSignIn: true
        }
      })
      if (onSignUpSuccess) {
        onSignUpSuccess({nextStep, data})
      }
    } catch (error) {
      setErrorMessage(error.message)
    } finally {
      setIsSigningUp(false)
    }
  }

  const [showPassword, setShowPassword] = useState(false)

  return (
    <div>
      {errorMessage && <div style={{color: 'red'}}>{errorMessage}</div>}
      <form
        onSubmit={handleSubmit(handleSignUp)}
        className="flex flex-col gap-2 mb-8 w-full"
      >
        <div>
          <label
            className={'label pb-1' + (errors.email ? ' text-red-error' : '')}
          >
            Email
          </label>
          <Input
            {...register('email', {required: true, pattern: /^\S+@\S+$/i})}
            type="email"
            name="email"
            className={'input-form' + (errors.email ? ' input-form-error' : '')}
            required
          />
          {errors.email?.type === 'required' && (
            <p className="text-red-500" role="alert">
              Email is required
            </p>
          )}
          {errors.email?.type === 'pattern' && (
            <p className="text-red-500" role="alert">
              Invalid email address
            </p>
          )}
        </div>
        <div>
          <label
            className={
              'label pb-1' + (errors.phone_number ? ' text-red-error' : '')
            }
          >
            Phone Number:
          </label>
          <PhoneInputWithCountry
            placeholder="Enter phone number"
            defaultCountry="US"
            name="phone_number"
            control={control}
            className={
              'PhoneInput' + (errors.phone_number ? ' input-form-error' : '')
            }
          />
          {errors.phone_number?.type === 'required' && (
            <p className="text-red-500" role="alert">
              Phone number is required
            </p>
          )}
          {errors.phone_number?.type === 'pattern' && (
            <p className="text-red-500" role="alert">
              Invalid phone number
            </p>
          )}
        </div>
        <div>
          <label
            className={
              'label pb-1' + (errors.given_name ? ' text-red-error' : '')
            }
          >
            First Name:
          </label>
          <Input
            {...register('given_name', {
              required: true,
              pattern: /^[A-Za-z]+$/i
            })}
            className={
              'input-form' + (errors.given_name ? ' input-form-error' : '')
            }
            type="text"
            name="given_name"
          />
          {errors.given_name?.type === 'required' && (
            <p className="text-red-500" role="alert">
              First name is required
            </p>
          )}
          {errors.given_name?.type === 'pattern' && (
            <p className="text-red-500" role="alert">
              Invalid first name
            </p>
          )}
        </div>
        <div>
          <label
            className={
              'label pb-1' + (errors.family_name ? ' text-red-error' : '')
            }
          >
            Last Name:
          </label>
          <Input
            {...register('family_name', {
              required: true,
              pattern: /^[A-Za-z]+$/i
            })}
            className={
              'input-form' + (errors.family_name ? ' input-form-error' : '')
            }
            type="text"
            name="family_name"
          />
          {errors.family_name?.type === 'required' && (
            <p className="text-red-500" role="alert">
              Last name is required
            </p>
          )}
          {errors.family_name?.type === 'pattern' && (
            <p className="text-red-500" role="alert">
              Invalid last name
            </p>
          )}
        </div>
        <div>
          <label
            className={
              'label pb-1' + (errors.password ? ' text-red-error' : '')
            }
          >
            Password:
          </label>
          <div className="relative">
            <Input
              {...register('password', {required: true})}
              className={
                'input-form' + (errors.password ? ' input-form-error' : '')
              }
              type={showPassword ? 'text' : 'password'}
              name="password"
            />
            <span
              className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <FontAwesomeIcon className="text-gray-400" icon={faEye} />
              ) : (
                <FontAwesomeIcon className="text-gray-400" icon={faEyeSlash} />
              )}
            </span>
          </div>
          {errors.password?.type === 'required' && (
            <p className="text-red-500" role="alert">
              Password is required
            </p>
          )}
        </div>
        <Button color="secondary" className="w-full mt-8" type="submit">
          create account
        </Button>
        <div className={clsx({hidden: !isSigningUp})}>
          <LoadingSpinner size={LoadingSpinnerSize.Small} />
        </div>
      </form>
    </div>
  )
}

export default CreateMemberForm
