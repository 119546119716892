import React from 'react'
import {Modal} from 'react-daisyui'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faClose, faEye} from '@fortawesome/free-solid-svg-icons'

interface ManageReservationProps {
  reservation: {
    cancellationPolicy: string
  }
}

const CancellationPolicy = ({reservation}: ManageReservationProps) => {
  const {Dialog, handleShow} = Modal.useDialog()

  return (
    <div className="">
      <Dialog>
        <form method="dialog relative">
          <div className="absolute top-0 right-0">
            <button className="btn-close">
              <FontAwesomeIcon
                icon={faClose}
                color="cabana"
                className="text-xs text-center"
                onClick={handleShow}
              />
            </button>
          </div>
        </form>
        <Modal.Header className="font-bold text-2xl mb-4">
          Cancellation Policy
        </Modal.Header>
        <Modal.Body>{reservation.cancellationPolicy}</Modal.Body>
      </Dialog>
      <div className="pt-4 cursor-pointer" onClick={handleShow}>
        <a className="text-midnight">
          <FontAwesomeIcon icon={faEye} className="text-xs pr-2 text-cabana" />
          View cancellation policy
        </a>
      </div>
    </div>
  )
}

export default CancellationPolicy
