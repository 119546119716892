import React, {useState} from 'react'
import {autoSignIn, fetchAuthSession} from 'aws-amplify/auth'
import LoadingSpinner, {LoadingSpinnerSize} from '../common/LoadingSpinner'
import clsx from 'clsx'
import {faRightToBracket} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import CreateMemberForm from './CreateMemberForm'
import {useNavigate} from 'react-router-dom'
import {ConfirmSignUpCodeForm} from './ConfirmSignUpCodeFirm'

enum SignUpStep {
  SIGN_UP,
  CONFIRM_SIGN_UP,
  COMPLETE_AUTO_SIGN_IN
}

interface CreateMemberAccountProps {
  onLoginSuccess: (token: string) => void
  onLoginFailure: (error: Error) => void
  confirming?: boolean
}

const CreateMemberAccount: React.FC<CreateMemberAccountProps> = ({
  onLoginSuccess,
  onLoginFailure,
  confirming = false
}) => {
  const navigate = useNavigate()
  const [isAuthenticating, setIsAuthenticating] = useState(false)

  const [formData, setFormData] = useState({
    email: '',
    phone_number: '',
    given_name: '',
    family_name: '',
    password: ''
  })

  const startStep = confirming ? SignUpStep.CONFIRM_SIGN_UP : SignUpStep.SIGN_UP

  const [signUpStep, setSignUpStep] = useState(startStep)

  async function handleAutoSignIn() {
    try {
      const response = await autoSignIn()
      const {accessToken, idToken} = (await fetchAuthSession()).tokens ?? {}

      // const token = response.signInUserSession?.idToken?.jwtToken
      onLoginSuccess(idToken.toString())
      // handle sign-in steps
    } catch (error) {
      console.log(error)
      navigate('/login')
    }
  }

  switch (signUpStep) {
    case SignUpStep.SIGN_UP:
      return (
        <CreateMemberForm
          onSignUpSuccess={({nextStep, data}: any) => {
            setFormData(data)
            setSignUpStep(
              nextStep?.signUpStep === 'CONFIRM_SIGN_UP'
                ? SignUpStep.CONFIRM_SIGN_UP
                : SignUpStep.SIGN_UP
            )
          }}
        />
      )
    case SignUpStep.CONFIRM_SIGN_UP:
      return (
        <ConfirmSignUpCodeForm
          username={formData.email}
          onConfirmed={({nextStep, data}: any) => {
            handleAutoSignIn()
            setIsAuthenticating(true)
            setSignUpStep(
              nextStep?.signUpStep === 'COMPLETE_AUTO_SIGN_IN'
                ? SignUpStep.COMPLETE_AUTO_SIGN_IN
                : SignUpStep.CONFIRM_SIGN_UP
            )
          }}
        />
      )
    case SignUpStep.COMPLETE_AUTO_SIGN_IN:
      return (
        <div>
          <div className={clsx({hidden: !isAuthenticating})}>
            <LoadingSpinner size={LoadingSpinnerSize.Small} />
          </div>
          <div className="text-center">
            <FontAwesomeIcon icon={faRightToBracket} className="mr-3" />
            {isAuthenticating ? 'logging in...' : 'log in'}
          </div>
        </div>
      )
  }
}

export default CreateMemberAccount
