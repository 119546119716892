import React, {useEffect} from 'react'
import LoadingSpinner, {LoadingSpinnerSize} from './common/LoadingSpinner'
import {useAppDispatch, useAppSelector} from '../store/hooks'
import {getUserToken, getUser} from '../services/token'
import {useValidateTokenMutation} from '../services/guestCheckinApi'
import {selectIsInitialized, setInitialized} from '../store/appSlice'
import {setUser} from '../store/userSlice'
import {AnyAction, Dispatch} from '@reduxjs/toolkit'
import {getLogger} from '../services/logging'
import AppRoutes from './AppRoutes'
import {UserRole} from '../types'
import {fetchUserAttributes} from 'aws-amplify/auth'

const logger = getLogger('App')

function initUserContext(
  dispatch: Dispatch<AnyAction>,
  validateToken: Function
): void {
  // read user token and determine authentication status
  const token = getUserToken()

  logger.info({message: 'Initializing user context...', data: {token}})

  if (!token) {
    logger.info({message: 'No token found'})
    dispatch(setInitialized(true))
  } else {
    logger.info({message: 'Validating token', data: {token}})
    validateToken(token)
      .unwrap()
      .then(async () => {
        logger.info({message: 'Token is valid', data: {token}})
        const user = getUser(token)

        if (user.role === UserRole.Member) {
          const response = await fetchUserAttributes()
          const updatedUser = {
            ...user,
            email: response.email.toString(),
            emailVerified: (response.email_verified as unknown) as boolean,
            firstName: response.given_name.toString(),
            isAuthenticated: true,
            lastName: response.family_name.toString(),
            phoneNumber: response.phone_number.toString(),
            phoneNumberVerified: (response.phone_number_verified as unknown) as boolean,
            role: UserRole.Member
          }
          dispatch(setUser(updatedUser))
        } else {
          dispatch(setUser(user))
        }
        dispatch(setInitialized(true))
      })
      .catch(() => {
        // set authentication status, nullify token/user, redirect to login
        logger.info({message: 'Token is invalid', data: {token}})
        dispatch(setInitialized(true))
      })
  }
}

const App = () => {
  const dispatch = useAppDispatch()
  const [validateToken] = useValidateTokenMutation()
  const isInitialized = useAppSelector(selectIsInitialized)

  useEffect(() => {
    if (!isInitialized) {
      initUserContext(dispatch, validateToken)
    }
  }, [])

  if (isInitialized) {
    return (
      <main>
        <AppRoutes />
      </main>
    )
  } else {
    return (
      <main>
        <div className="min-h-screen flex flex-row justify-center items-center gap-2">
          <LoadingSpinner size={LoadingSpinnerSize.Large} />
          <div>Loading...</div>
        </div>
      </main>
    )
  }
}

export default App
