import React, {useEffect, useState} from 'react'
import StayCard from './StayCard'
import {useGetMemberReservationsQuery} from '../../services/guestPortalApi'
import LoadingSpinner, {LoadingSpinnerSize} from '../common/LoadingSpinner'
import {Button, Modal} from 'react-daisyui'
import AddOTAReservationForm from './AddOTAReservationForm'
import StaysEmptyState from '../../assets/stays-empty-state.png'

const MemberStays = ({onManageStay}: {onManageStay: (stay: any) => void}) => {
  const staysQuery = useGetMemberReservationsQuery({
    reservationStatus: 'Confirmed,InHouse'
  })
  const canceledStaysQuery = useGetMemberReservationsQuery({
    reservationStatus: 'Canceled,NoShow'
  })
  const pastStaysQuery = useGetMemberReservationsQuery({
    reservationStatus: 'CheckedOut'
  })

  const {Dialog, handleShow} = Modal.useDialog()
  const [
    showAddOTAReservationDialog,
    setshowAddOTAReservationDialog
  ] = useState(false)

  useEffect(() => {}, [staysQuery, canceledStaysQuery, pastStaysQuery])

  return (
    <div className="rows gap-4">
      <Dialog
        open={showAddOTAReservationDialog}
        onClose={() => setshowAddOTAReservationDialog(false)}
        className="modal-box bg-white max-w-sm"
      >
        <AddOTAReservationForm
          onSignUpSuccess={() => {
            setshowAddOTAReservationDialog(false)
            staysQuery.refetch()
            canceledStaysQuery.refetch()
            pastStaysQuery.refetch()
          }}
        ></AddOTAReservationForm>
        <form method="dialog">
          <Button className="btn btn-link no-underline text-sm w-full">
            cancel
          </Button>
        </form>
      </Dialog>

      <div className="grid grid-cols-1 gap-6">
        <div>
          {staysQuery.isLoading && (
            <LoadingSpinner size={LoadingSpinnerSize.Large}></LoadingSpinner>
          )}

          {!staysQuery.isLoading &&
            (!staysQuery.data || staysQuery.data.length < 1) && (
              <div className="flex flex-col items-center gap-4">
                <img src={StaysEmptyState} className="h-52 my-8 mx-auto" />
                <h3 className="text-lg text-center font-bold">
                  You don't have any stays coming up.
                </h3>
                <Button
                  className="center btn-sm lowercase text-center"
                  color="secondary"
                  onClick={() =>
                    window.open(
                      'https://www.placemakr.com/book#/booking/search'
                    )
                  }
                >
                  book now
                </Button>
              </div>
            )}

          {staysQuery.data && staysQuery.data?.length > 0 ? (
            <div>
              <h1 className="text-xl font-semibold py-4">
                Active Reservations
              </h1>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                {staysQuery.data?.map((stay: any, index: number) => (
                  <div
                    key={`stay-${stay.reservationId}`}
                    className="max-w-[500px]"
                  >
                    <StayCard
                      stay={stay}
                      viewReservation={true}
                      onManageStay={onManageStay}
                    ></StayCard>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            ''
          )}
        </div>

        {!staysQuery.isLoading && (
          <div className="flex flex-col items-center">
            <div>
              Did you book with a third party? Add your reservation{' '}
              <span
                className="btn-link font-semibold cursor-pointer no-underline text-md btn-secondary p-0"
                onClick={() => setshowAddOTAReservationDialog(true)}
                color="secondary"
              >
                here
              </span>
            </div>
          </div>
        )}

        <div>
          {pastStaysQuery.data && pastStaysQuery.data?.length > 0 ? (
            <div>
              <h1 className="text-xl font-semibold py-4">Past Reservations</h1>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                {pastStaysQuery.data?.map((stay: any, index: number) => (
                  <div
                    key={`stay-${stay.reservationId}`}
                    className="max-w-[500px]"
                  >
                    <StayCard
                      stay={stay}
                      viewReservation={false}
                      onManageStay={onManageStay}
                    ></StayCard>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
        <div>
          {canceledStaysQuery.data && canceledStaysQuery.data?.length > 0 ? (
            <div>
              <h1 className="text-xl font-semibold py-4">
                Canceled Reservations
              </h1>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                {canceledStaysQuery.data?.map((stay: any, index: number) => (
                  <div
                    key={`stay-${stay.reservationId}`}
                    className="max-w-[500px]"
                  >
                    <StayCard
                      stay={stay}
                      viewReservation={false}
                      onManageStay={onManageStay}
                    ></StayCard>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  )
}

export default MemberStays
