import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {
  confirmUserAttribute,
  updateMFAPreference,
  fetchMFAPreference,
  updateUserAttributes,
  sendUserAttributeVerificationCode,
  updatePassword,
  resetPassword,
  confirmResetPassword
} from 'aws-amplify/auth'

export const cognitoApi = createApi({
  reducerPath: 'cognitoApi',
  baseQuery: fetchBaseQuery({baseUrl: '/'}),
  tagTypes: ['MFA'],
  endpoints: builder => ({
    getPreferredMFA: builder.query({
      providesTags: ['MFA'],
      async queryFn() {
        try {
          const data = await fetchMFAPreference()
          return {data}
        } catch (error) {
          return {error}
        }
      }
    }),
    updatePreferredMFA: builder.mutation({
      invalidatesTags: ['MFA'],
      async queryFn(preferredMfa) {
        try {
          const data = await updateMFAPreference(preferredMfa)
          return {data}
        } catch (error) {
          return {error}
        }
      }
    }),
    verifyUserPhoneNumber: builder.mutation({
      async queryFn(code) {
        try {
          const data = await confirmUserAttribute({
            userAttributeKey: 'phone_number',
            confirmationCode: code
          })
          return {data}
        } catch (error) {
          return {error}
        }
      }
    }),
    updateUserAttributes: builder.mutation({
      async queryFn(attributes) {
        try {
          const data = await updateUserAttributes({userAttributes: attributes})
          return {data}
        } catch (error) {
          return {error}
        }
      }
    }),
    sendUserAttributeVerificationCode: builder.mutation({
      async queryFn(key) {
        try {
          const data = await sendUserAttributeVerificationCode({
            userAttributeKey: key
          })
          return {data}
        } catch (error) {
          return {error}
        }
      }
    }),
    verifyUserAttribute: builder.mutation({
      async queryFn({userAttributeKey, confirmationCode}) {
        try {
          const data = await confirmUserAttribute({
            userAttributeKey,
            confirmationCode
          })
          return {data}
        } catch (error) {
          return {error}
        }
      }
    }),
    updatePassword: builder.mutation({
      async queryFn({oldPassword, newPassword}) {
        try {
          const data = await updatePassword({oldPassword, newPassword})
          return {data}
        } catch (error) {
          return {error: error.message}
        }
      }
    }),
    sendResetPasswordCode: builder.mutation({
      async queryFn({username}) {
        try {
          const data = await resetPassword({username})
          return {data}
        } catch (error) {
          return {error}
        }
      }
    }),
    confirmResetPassword: builder.mutation({
      async queryFn({username, confirmationCode, newPassword}) {
        try {
          const data = await confirmResetPassword({
            username,
            confirmationCode,
            newPassword
          })
          return {data}
        } catch (error) {
          return {error}
        }
      }
    })
  })
})

export const {
  useGetPreferredMFAQuery,
  useUpdatePreferredMFAMutation,
  useUpdateUserAttributesMutation,
  useVerifyUserPhoneNumberMutation,
  useUpdatePasswordMutation,
  useSendUserAttributeVerificationCodeMutation,
  useVerifyUserAttributeMutation,
  useSendResetPasswordCodeMutation,
  useConfirmResetPasswordMutation
} = cognitoApi
