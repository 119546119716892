import 'react-phone-number-input/style.css'
import React, {useState} from 'react'
import {useAppSelector} from '../../store/hooks'
import {selectUser, setUser} from '../../store/userSlice'
import LoadingSpinner, {LoadingSpinnerSize} from '../common/LoadingSpinner'
import {Button, Input, Modal} from 'react-daisyui'
import ResetPassword from './ResetPassword'
import VerifyUserAttribute from './VerifyUserAttribute'
import NotificationPreferences from './NotificationPreferences'
import {useForm} from 'react-hook-form'
import {updateUserAttributes} from 'aws-amplify/auth'
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form'
import {userInfo} from 'os'
import {UserRole} from '../../types'

interface UserAttributes {
  family_name?: string
  given_name?: string
  email?: string
  phone_number?: string
}

export const EditProfile = () => {
  const user = useAppSelector(selectUser)
  const [isUpdating, setIsUpdating] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: {errors, isDirty},
    watch
  } = useForm<UserAttributes>({
    defaultValues: {
      given_name: user.firstName,
      family_name: user.lastName,
      email: user.email,
      phone_number: user.phoneNumber
    }
  })

  const [showVerifyPhone, setShowVerifyPhone] = useState(false)
  const [showVerifyEmail, setShowVerifyEmail] = useState(false)

  const [verification, setVerification] = useState({
    emailVerified: user.emailVerified,
    phoneVerified: user.phoneNumberVerified
  })

  const [editting, setEditting] = useState(false)

  const handleEditProfile = async (data: any) => {
    setEditting(false)
    setIsUpdating(true)
    try {
      const response = await updateUserAttributes({userAttributes: data})
      const updatedUser = {
        ...user,
        email: response.email.toString(),
        emailVerified: (response.email_verified as unknown) as boolean,
        firstName: response.given_name.toString(),
        isAuthenticated: true,
        lastName: response.family_name.toString(),
        phoneNumber: response.phone_number.toString(),
        phoneNumberVerified: (response.phone_number_verified as unknown) as boolean,
        role: UserRole.Member
      }
      dispatch(setUser(updatedUser))
      reset(data)
    } catch (error) {
      setErrorMessage(error.message)
    } finally {
      setIsUpdating(false)
    }
  }

  const {Dialog} = Modal.useDialog()

  const [showResetPwdDialog, setShowResetPwdDialog] = useState(false)

  const handleCancelEdit = () => {
    reset()
    setEditting(false)
  }

  const onPasswordResetSuccess = () => {
    setShowResetPwdDialog(false)
  }

  return (
    <div className="container">
      <Dialog
        open={showVerifyPhone}
        onClose={() => setShowVerifyPhone(false)}
        className="modal-box bg-white max-w-sm"
      >
        <VerifyUserAttribute
          attributeName="Phone Number"
          attribute="phone_number"
          attributeValue={watch('phone_number')}
        ></VerifyUserAttribute>
        <Modal.Actions></Modal.Actions>
      </Dialog>
      <Dialog
        open={showVerifyEmail}
        onClose={() => setShowVerifyEmail(false)}
        className="modal-box bg-white max-w-sm"
      >
        <VerifyUserAttribute
          attributeName="Email"
          attribute="email"
          attributeValue={watch('email')}
        ></VerifyUserAttribute>
      </Dialog>
      <Dialog
        open={showResetPwdDialog}
        onClose={() => setShowResetPwdDialog(false)}
        className="modal-box bg-white max-w-sm"
      >
        <ResetPassword onSuccess={onPasswordResetSuccess}></ResetPassword>
        <form method="dialog">
          <Button className="btn btn-link no-underline text-sm w-full">
            cancel
          </Button>
        </form>
        {/* <ResetPasswordCode username={user.email}></ResetPasswordCode> */}
      </Dialog>
      <div className="grid grid-flow-row gap-4">
        <h1 className="text-xl font-semibold">Edit my info</h1>
        <p className="text-sm font-light">
          Update your profile information here.
        </p>
        <form
          onSubmit={handleSubmit(handleEditProfile)}
          onReset={handleCancelEdit}
          noValidate
        >
          <div className="form-control grid grid-cols-1 sm:grid-cols-2 gap-4 max-w-2xl">
            <div className="col-span-2 sm:col-span-1">
              <label className="label pt-2 pb-1">
                <span
                  className={
                    'label-text' + (errors.given_name ? ' text-red-error' : '')
                  }
                >
                  First Name
                </span>
              </label>
              <Input
                id="first_name"
                name="first_name"
                className={
                  'input-form' + (errors.given_name ? ' input-form-error' : '')
                }
                required
                {...register('given_name', {required: true})}
              />
              {errors.given_name?.type === 'required' && (
                <p className="text-red-error" role="alert">
                  First name is required
                </p>
              )}
            </div>

            <div className="col-span-2 sm:col-span-1">
              <label className="label pb-1">
                <span
                  className={
                    'label-text' + (errors.family_name ? ' text-red-error' : '')
                  }
                >
                  Last Name
                </span>
              </label>
              <Input
                id="family_name"
                name="family_name"
                required
                className={
                  'input-form' + (errors.family_name ? ' input-form-error' : '')
                }
                {...register('family_name', {required: true})}
              />
              {errors.family_name?.type === 'required' && (
                <p className="text-red-error" role="alert">
                  Last name is required
                </p>
              )}
            </div>

            <div className="col-span-2 sm:col-span-1">
              <label className="label pb-1">
                <span className="label-text">Phone Number</span>
                <span className="label-text-alt">
                  {verification.phoneVerified ? (
                    'Verified'
                  ) : (
                    <div
                      className="btn btn-link btn-xs no-underline text-xs text-red-800"
                      onClick={() => setShowVerifyPhone(true)}
                    >
                      Verify
                    </div>
                  )}
                </span>
              </label>
              <PhoneInputWithCountry
                placeholder="Enter phone number"
                // layout="second"
                // metadata={metadata}
                defaultCountry="US"
                name="phone_number"
                control={control}
                className="PhoneInput"
                // metadata={{country: 'US'}}
              />
            </div>

            <div className="col-span-2 sm:col-span-1">
              <label className="label pb-1">
                <span
                  className={
                    'label-text' + (errors.email ? ' text-red-error' : '')
                  }
                >
                  Email
                </span>
                <span className="label-text-alt text-cabana">
                  {verification.emailVerified ? (
                    'Verified'
                  ) : (
                    <div
                      className="btn btn-xs btn-link no-underline text-sm text-red-800"
                      onClick={() => setShowVerifyEmail(true)}
                    >
                      Verify
                    </div>
                  )}
                </span>
              </label>
              <Input
                id="email"
                name="email"
                required
                type="email"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                className={
                  'input-form' + (errors.email ? ' input-form-error' : '')
                }
                disabled={true}
                {...register('email', {required: true})}
              />
              {errors.email?.type === 'required' && (
                <p className="text-red-error" role="alert">
                  Email is required
                </p>
              )}
            </div>

            <div className="col-span-2 sm:col-span-1">
              <label className="label pt-6 pb-1">
                <span className="label-text">Password</span>
              </label>
              <Input
                id="password"
                name="password"
                type="password"
                disabled={true}
                value={'********'}
                required
                className={'input-form'}
              />
              <div className="pt-4 cursor-pointer sm:col-span-2 sm:col-span-1">
                <a
                  className="text-cabana"
                  onClick={e => {
                    setShowResetPwdDialog(true)
                  }}
                >
                  Reset Password
                </a>
              </div>
            </div>
            <div></div>
            <div className="col-span-2">
              {isUpdating ? (
                <LoadingSpinner size={LoadingSpinnerSize.Large} />
              ) : (
                ''
              )}
              {isDirty ? (
                <div>
                  <Button
                    className="btn btn-link no-underline"
                    type="reset"
                    onClick={e => handleCancelEdit()}
                  >
                    cancel
                  </Button>
                  <Button className="btn btn-secondary" type="submit">
                    Save
                  </Button>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </form>

        <NotificationPreferences />
      </div>
    </div>
  )
}
function dispatch(arg0: {
  payload: import('../../types').AuthenticatedUser
  type: 'user/setUser'
}) {
  throw new Error('Function not implemented.')
}
