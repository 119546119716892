import React, {useState} from 'react'
import {Button, Input} from 'react-daisyui'
import {confirmResetPassword} from 'aws-amplify/auth'
import LoadingSpinner, {LoadingSpinnerSize} from '../common/LoadingSpinner'
import clsx from 'clsx'
import {useForm} from 'react-hook-form'

export const ConfirmPasswordResetForm = ({
  username,
  onConfirmed
}: {
  username: string
  onConfirmed?: Function
}) => {
  const {
    register,
    handleSubmit,
    formState: {errors},
    watch
  } = useForm()
  const [isConfirming, setIsConfirming] = useState(false)

  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const handleConfirmCode = async (data: any): Promise<void> => {
    setIsConfirming(true)
    try {
      await confirmResetPassword({
        username,
        newPassword: data.newPassword,
        confirmationCode: data.verificationCode
      })
      if (onConfirmed) {
        onConfirmed()
      }
    } catch (error) {
      setErrorMessage(error.message || 'An error occurred')
    } finally {
      setIsConfirming(false)
    }
  }

  return (
    <form
      className="grid grid-cols-1 gap-2"
      onSubmit={handleSubmit(handleConfirmCode)}
    >
      {errorMessage && <div className="text-red-500">{errorMessage}</div>}
      <div>
        <label
          className={
            'label ' + (errors.verificationCode ? ' text-red-error' : '')
          }
        >
          Enter Verification Code
        </label>
        <Input
          {...register('verificationCode', {required: true})}
          name="verificationCode"
          className={
            'input-form' + (errors.verificationCode ? ' input-form-error' : '')
          }
        ></Input>
        {errors.verificationCode?.type === 'required' && (
          <p role="alert">Verification code is required</p>
        )}
      </div>
      <div>
        <label
          className={'label ' + (errors.newPassword ? ' text-red-error' : '')}
        >
          New Password
        </label>

        <Input
          {...register('newPassword', {required: true})}
          name="newPassword"
          type="password"
          className={
            'input-form' + (errors.newPassword ? ' input-form-error' : '')
          }
        ></Input>
        {errors.newPassword?.type === 'required' && (
          <p role="alert">New password is required</p>
        )}
      </div>
      <div>
        <label
          className={
            'label ' + (errors.confirmPassword ? ' text-red-error' : '')
          }
        >
          Confirm Password
        </label>

        <Input
          {...register('confirmPassword', {
            required: true,
            validate: (val: string) => {
              if (watch('newPassword') !== val) {
                return 'match'
              }
            }
          })}
          name="confirmPassword"
          type="password"
          className={
            'input-form' + (errors.confirmPassword ? ' input-form-error' : '')
          }
        ></Input>
        {errors.confirmPassword?.type === 'match' && (
          <p role="alert">Invalid email address</p>
        )}
      </div>
      <Button type="submit" className="mt-4">
        Confirm
      </Button>
      <div className={clsx({hidden: !isConfirming})}>
        <LoadingSpinner size={LoadingSpinnerSize.Small} />
      </div>
    </form>
  )
}

export default ConfirmPasswordResetForm
