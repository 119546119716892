import React, {useState} from 'react'
import {Button, Input} from 'react-daisyui'
import {resetPassword} from 'aws-amplify/auth'
import LoadingSpinner, {LoadingSpinnerSize} from '../common/LoadingSpinner'
import clsx from 'clsx'
import {useForm} from 'react-hook-form'

export const SendResetPasswordCodeForm = ({
  onCodeSent
}: {
  onCodeSent?: Function
}) => {
  const {
    register,
    handleSubmit,
    formState: {errors}
  } = useForm()
  const [isSendingCode, setIsSendingCode] = useState(false)

  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const handleSendCode = async (data: any): Promise<void> => {
    setIsSendingCode(true)
    try {
      const response = await resetPassword({username: data.email})
      if (onCodeSent) {
        onCodeSent({response, data})
      }
    } catch (error) {
      setErrorMessage(error.message || 'An error occurred')
    } finally {
      setIsSendingCode(false)
    }
  }

  return (
    <form onSubmit={handleSubmit(handleSendCode)}>
      {errorMessage && <div className="text-red-500">{errorMessage}</div>}
      <label className="label pt-6 pb-1">
        <span className="label-text">Email</span>
      </label>
      <div>
        <Input
          aria-invalid={errors.firstName ? 'true' : 'false'}
          {...register('email', {required: true, pattern: /^\S+@\S+$/i})}
          className={'input-form' + (errors.email ? ' input-error' : '')}
        ></Input>
        {errors.email?.type === 'required' && (
          <p className="text-red-500" role="alert">
            Email is required
          </p>
        )}
        {errors.email?.type === 'pattern' && (
          <p className="text-red-500" role="alert">
            Invalid email address
          </p>
        )}
      </div>
      <Button className="w-full mt-8" color="secondary" type="submit">
        Send Code
      </Button>
      <div className={clsx({hidden: !isSendingCode})}>
        <LoadingSpinner size={LoadingSpinnerSize.Small} />
      </div>
    </form>
  )
}

export default SendResetPasswordCodeForm
