import React, {useEffect, useState} from 'react'
import PaymentForm from '../common/PaymentForm'
import {
  useGetPaymentMethodsQuery,
  useGetPaymentSessionQuery
} from '../../services/guestPortalApi'

const MemberUpdatePayment = ({reservation}: {reservation: any}) => {
  const paymentMethodsQuery = useGetPaymentMethodsQuery({reservation})
  const paymentSessionQuery = useGetPaymentSessionQuery({reservation})

  return (
    <div className="rows gap-4 space-y-4 max-w-[600px]">
      {paymentMethodsQuery.data ? (
        <PaymentForm
          paymentMethods={paymentMethodsQuery.data}
          paymentSession={paymentSessionQuery.data}
          isSavedPayment={true}
          onPaymentCompleted={(data: any) => {
            // setViewState(ViewState.StatusLoading)
          }}
          onPaymentError={(err: any) => {
            // setViewState(ViewState.Error)
          }}
        ></PaymentForm>
      ) : (
        <div>loading...</div>
      )}
    </div>
  )
}

export default MemberUpdatePayment
