import {LogLevel} from '../types'
import {ResourcesConfig} from 'aws-amplify'

export function getAwsConfig(): ResourcesConfig {
  return {
    Auth: {
      Cognito: {
        userPoolId: process.env.COGNITO_USER_POOL_ID,
        userPoolClientId: process.env.COGNITO_WEB_CLIENT_ID,
        identityPoolId: null,
        allowGuestAccess: true,
        signUpVerificationMethod: 'code'
      }
    }
  }
}

declare var process: {
  env: {
    [key: string]: string
  }
}

export interface AppConfig {
  adyenClientId: string
  adyenEnvironment: string
  cognitoRegion: string
  cognitoUserPoolId: string
  cognitoWebClientId: string
  environment: string
  guestCheckinApiUrl: string
  guestPortalApiUrl: string
  guestTokenIssuer: string
  loggingApiUrl: string
  loggingEnabled: boolean
  loggingLevel: LogLevel
  metricsApiUrl: string
  metricsEnabled: boolean
  metricsNamespace: string
  reduxDevTools: boolean
}

export function getConfig(): AppConfig {
  return {
    adyenClientId: process.env.ADYEN_CLIENT_ID,
    adyenEnvironment: process.env.ADYEN_ENVIRONMENT,
    cognitoRegion: process.env.COGNITO_REGION,
    cognitoUserPoolId: process.env.COGNITO_USER_POOL_ID,
    cognitoWebClientId: process.env.COGNITO_WEB_CLIENT_ID,
    environment: process.env.ENVIRONMENT,
    guestCheckinApiUrl: process.env.GUEST_CHECKIN_API_URL,
    guestPortalApiUrl: process.env.GUEST_PORTAL_API_URL,
    guestTokenIssuer: process.env.GUEST_TOKEN_ISSUER,
    loggingApiUrl: process.env.LOGGING_API_URL,
    loggingEnabled: process.env.LOGGING_ENABLED === 'true',
    loggingLevel: process.env.LOGGING_LEVEL as LogLevel,
    metricsApiUrl: process.env.METRICS_API_URL,
    metricsEnabled: process.env.METRICS_ENABLED === 'true',
    metricsNamespace: process.env.METRICS_NAMESPACE,
    reduxDevTools: process.env.REDUX_DEV_TOOLS === 'true'
  }
}
