import React, {useEffect, useState} from 'react'
import ManageReservation from './ManageReservation'
import {useGetCMSPropertiesQuery} from '../../services/guestPortalApi'
import MemberStays from './MemberStays'

const MemberStaysTab = ({reservationId}: {reservationId?: string}) => {
  const propertiesQuery = useGetCMSPropertiesQuery({})

  const [manageStay, setManageStay] = useState(reservationId || false)
  const [currentStay, setCurrentStay] = useState(reservationId || null)

  const onManageStay = (stay: any) => {
    setManageStay(true)
    setCurrentStay(stay.reservationId)
  }

  const onReturnToStaysClick = () => setManageStay(false)

  const onStayModification = () => {
    setTimeout(() => {
      // There is a delay in the update of the reservation status returned here
      // so we need to wait a few seconds before refetching the data
      // staysQuery.refetch()
      // canceledStaysQuery.refetch()
    }, 4000)
  }

  useEffect(() => {}, [manageStay])

  return (
    <div className="rows gap-4">
      {manageStay && currentStay && propertiesQuery.data ? (
        <ManageReservation
          reservationId={currentStay}
          onMyStaysClick={onReturnToStaysClick}
          propertiesData={propertiesQuery.data}
          onStayModification={onStayModification}
        ></ManageReservation>
      ) : (
        <MemberStays onManageStay={onManageStay}></MemberStays>
      )}
    </div>
  )
}

export default MemberStaysTab
